@use "./node_modules/sdk-core-library/styles/sdk-styles.scss";

:root {
    /**************************************************************************
        Colors
    **************************************************************************/
    --blue: rgb(101, 153, 203);
    --blue-dark: rgb(71, 125, 177);
    --blue-light: rgb(123, 187, 203);

    --green: rgb(101, 153, 30);
    --green-dark: rgb(71, 125, 27);
    --green-light: rgb(141, 207, 43);

    --red: rgb(179, 4, 57);
    --red-dark: rgb(128, 0, 40);
    --red-light: rgb(224, 2, 70);

    --gray: rgb(175, 175, 175);
    --gray-dark: rgb(125, 125, 125);
    --gray-light: rgb(225, 225, 225);

    --white: rgb(245, 245, 245);
    --black: rgb(50, 50, 50);

    --background: rgb(225, 225, 225);

    /**************************************************************************
        Fonts
    **************************************************************************/
    --font-header: 'Helvetica Neue', sans-serif, Arial;
    --font-text: 'Helvetica Neue', sans-serif, Arial;
    --font-color: var(--gray-dark);

    /**************************************************************************
        Borders
    **************************************************************************/
    --border: var(--gray-light);
    --border-dark: var(--gray-dark);
    --border-blue: var(--blue-dark);

    /**************************************************************************
        SDK Overrides
    **************************************************************************/
    .sdk-icon {
        font-size: 2.0em;
    }

    * {
        --sdk-button: var(--blue);
        --sdk-border-blue: var(--blue);
        --sdk-blue: var(--blue);
        --sdk-red: var(--red);
        --sdk-green: var(--green);
    }
}

.icon {
    font-family: 'Material Symbols Outlined', sans-serif;
    font-variation-settings: 'FILL' 1;
    font-weight: normal;
    font-style: normal;
    font-size: 2.0em;
    font-display: block;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: 'liga';
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;

    &.cursor {
        cursor: pointer;
    }

    &.red {
        color: var(--red);
    }

    &.blue {
        color: var(--blue);
    }

    &.green {
        color: var(--green);
    }
}



/******************************************************************************
    Global
******************************************************************************/
* {
    font-family: var(--font-text);
    cursor: default;

    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    margin: 0;
    font-size: 0.9em;
    font-weight: 400;
    color: var(--font-color);
    line-height: 1.5;
}

html,
body {
    height: 100%;
}


/******************************************************************************
    Text
******************************************************************************/
h1 {
    font-size: 1.6rem;
    font-weight: 400;
    padding: 0;
    margin: 0;
}

h2 {
    font-size: 1.4rem;
    font-weight: 400;
    padding: 0;
    margin: 0;
}

h3 {
    font-size: 1.2rem;
    font-weight: 400;
    padding: 0;
    margin: 0;
}

p {
    padding: 0;
    margin: 0;
}

.blue {
    color: var(--blue);
}

.blue-dark {
    color: var(--blue-dark);
}

.red {
    color: var(--red);
}

.red-dark {
    color: var(--red-dark);
}

.green {
    color: var(--green);
}

.green-dark {
    color: var(--green-dark);
}


/******************************************************************************
    Hyperlink
******************************************************************************/
a {
    cursor: pointer;
    color: var(--blue);
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        opacity: 0.8;
    }
}


/******************************************************************************
    Button
******************************************************************************/
button {
    background-color: var(--blue);
    color: var(--white);
    border: 0.5px solid transparent;
    cursor: pointer;
    font-family: inherit;
    font-size: 14px;
    font-weight: 600;
    line-height: inherit;
    text-transform: uppercase;
    padding: 8px 16px;
    margin: 0 5px;
    display: inline-flex;
    box-shadow: 1px 1px 2px 1px rgb(140, 140, 140);
    border-radius: 4px;

    &.green {
        background-color: var(--green);
        color: var(--white);
    }

    &.red {
        background-color: var(--red);
        color: var(--white);
    }

    &.cancel {
        background-color: var(--gray);
        color: var(--white);
    }

    &.delete {
        background-color: transparent;
        color: var(--red);
        width: auto;
    }

    &.small {
        padding: 4px 8px;
    }

    &.font {
        font-family: 'Courier New', Courier, monospace;
    }

    &:hover {
        background-color: var(--white);
        color: var(--blue);
        border: 0.5px solid var(--blue);
    }

    &.disabled,
    :disabled {
        opacity: 0.5;
        cursor: default;
        background-color: var(--gray);

        &:hover {
            background-color: var(--gray);
            color: var(--white);
            border: 0.5px solid transparent;
        }
    }
}

/******************************************************************************
    Table
******************************************************************************/
table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    background-color: white;
    cursor: default;

    .pointer,
    .pointer * {
        cursor: pointer;
    }

    caption {
        display: none;
    }

    tr {
        th {
            font-family: var(--font-header);
            padding: 0.5rem;
            border: 1px solid var(--border);
            background-color: rgb(240, 240, 240);
            color: var(--blue-dark);
            text-align: left;
        }

        td {
            padding: 0.5rem;
            vertical-align: middle;
            text-align: left;
            border: 1px solid var(--border);
        }

        &:hover {
            background-color: rgb(228, 246, 253);
        }
    }

    thead {
        tr {
            th {
                padding: 0.5rem;
                vertical-align: middle;
                text-align: center;
                white-space: nowrap;
                font-family: var(--font-header);
                font-size: 1.1em;
                color: var(--blue-dark);
                border: 1px solid var(--border-dark);
                background-color: var(--gray-light)
            }

            td {
                padding: 0.5rem;
                vertical-align: middle;
                text-align: center;
                border: 1px solid var(--border-dark);
            }
        }
    }

    tbody {
        tr {
            th {
                font-family: var(--font-header);
                padding: 0.5rem;
                border: 1px solid var(--border);
                background-color: rgb(240, 240, 240);
                color: var(--blue-dark);
                text-align: left;
            }

            td {
                padding: 0.5rem;
                vertical-align: middle;
                text-align: left;
                border: 1px solid var(--border);
            }

            &:hover {
                background-color: rgb(228, 246, 253);
            }
        }
    }

    tfoot {
        tr {
            th {
                padding: 0.5rem;
                vertical-align: middle;
                text-align: center;
                white-space: nowrap;
                font-family: var(--font-header);
                font-size: 1.2em;
                color: var(--blue-dark);
                border: 1px solid var(--border-dark);
                background-color: var(--gray);
            }

            td {
                padding: 0.5rem;
                vertical-align: middle;
                text-align: center;
                border: 1px solid var(--border-dark);
            }
        }
    }
}


/******************************************************************************
    Custom
******************************************************************************/
.hidden {
    display: none;
}

.block {
    display: inline-block;
}

.inline {
    display: inline-flex;
}

.show {
    visibility: visible;
}

.hide {
    visibility: hidden;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.bold {
    font-weight: 600;
}

.light {
    font-weight: 300;
}

.nowrap {
    white-space: nowrap;
}

.selected,
.selected * {
    color: var(--blue);
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

/******************************************************************************
*	Mobile/Tablet
******************************************************************************/
@media screen and (max-width: 750px) {}